<template>
    <div class="card card-shadow">
        <div class="card-inner">
            <div class="card-title-group align-start mb-2">
                <div class="card-title">
                    <h6 class="title">Tickets By Priority Summary</h6>
                </div>
                <div class="card-tools mt-n1 mr-n1">
                  <div class="btn-group ">
                    <button @click="setMonth('thisMonth')" type="button" class="btn btn-primary btn-sm">This Month</button>
                    <button @click="setMonth('lastMonth')" type="button" class="btn btn-secondary btn-sm">Last Month</button>
                    <button @click="setMonth('monthBeforeLast')" type="button" class="btn btn-secondary btn-sm">Month <em style="font-size:10px;" class="icon ni ni-caret-left-fill"></em> Last</button>
                    <a-date-picker style="margin-left: 5px;" placeholder="Start Date" v-model="widgetFetcher.start" @change="getStats"/>
                    <a-date-picker style="margin-left: 5px;" placeholder="End Date" v-model="widgetFetcher.end" @change="getStats"/>
                  </div>
                </div>
            </div>
            <apexchart v-if="loaded" :height="'250px'" :options="chartOptions" :series="series"></apexchart>
            <div v-if="!series">
                <p class="text-center alert" >No Data to display</p>
            </div>
        </div>
    </div><!-- .card -->
</template>

<script>
export default {
    data() {
        return {
          ticketStats: null,
          widgetFetcher: {
            start: this.$moment().startOf('month'),
            end: this.$moment().endOf('month'),
          },
          loaded: false,
          series: [],
          chartOptions: {
            colors: ['#6576FF','#1EE0AC', '#E85347', '#364A63', '#0AC2DE', '#F4BD0D', '#8091A7'],
            fill: {
              colors: ['#6576FF','#1EE0AC', '#E85347', '#364A63', '#0AC2DE', '#F4BD0D', '#8091A7']
            },
            dataLabels: {
              enabled: false,
              style: {
                colors: ['#6576FF','#1EE0AC', '#E85347', '#364A63', '#0AC2DE', '#F4BD0D', '#8091A7']
              }
            },
            markers: {
              colors: ['#6576FF','#1EE0AC', '#E85347', '#364A63', '#0AC2DE', '#F4BD0D', '#8091A7']
            },
            chart: {
              width: 200,
              height: 250,
              type: 'bar'
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                }
            },
            stroke: {
              curve: 'smooth'
            },
            xaxis: {
              categories: []
            },
            tooltip: {
              x: {
                format: 'dd/MM/yy HH:mm' + 'Of the Month'
              },
            },
          },
        }
    },
    created() {
        this.getStats()
    },
    methods:{
        getStats() {
            this.loaded = false
            this.$http.post('/widgets/Get_TicketsByPriorityChart/', this.widgetFetcher)
            .then((response) => {
              this.ticketStats = response.data
              let xaxis  = [] 
              let series  = [{name: 'Opened', data: []},{name: 'Completed', data: []},{name: 'Missed SLA', data: []}]
              response.data.forEach(element => {
                xaxis.push(element.name)
                series[0].data.push(element.openedCount)
                series[1].data.push(element.closedCount)
                series[2].data.push(element.missedSLACount)
                //alert(element.openedCount)
              });
              this.chartOptions.xaxis.categories = xaxis
              this.series = series
            
                this.loaded = true
            })
        },
        setMonth(s){
            if (s === 'thisMonth') {
                this.widgetFetcher.start = this.$moment().startOf('month'),
                this.widgetFetcher.end = this.$moment().endOf('month')
            } else if (s === 'lastMonth') {
                this.widgetFetcher.start = this.$moment().subtract(1, 'months').startOf('month'),
                this.widgetFetcher.end = this.$moment().subtract(1, 'months').endOf('month')
            } else if (s === 'monthBeforeLast') {
                this.widgetFetcher.start = this.$moment().subtract(2, 'months').startOf('month'),
                this.widgetFetcher.end = this.$moment().subtract(2, 'months').endOf('month')
            }
            this.getStats()
        },
    }
}
</script>

<style>

</style>